<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<ng-container *ngIf="groupId">

  <mat-card *appHasAPermission="let i;group:groupId; permission:'can_access_dashboard'" class="is-link mat-elevation-z3" routerLink="../dashboard">
    <mat-card-header>
        <div mat-card-avatar><i class="material-icons tangy-foreground-secondary group-avatar">dashboard</i>
        </div>
        <mat-card-title>
            <a>Dashboard</a>
        </mat-card-title>
        <mat-card-subtitle>Dashboard with custom reports.</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  
  <span *ngIf="config.enabledModules.includes('case')">
    <mat-card *appHasAPermission="let i;group:groupId; permission:'can_access_cases'"  class="mat-elevation-z3">
      <mat-card-header>
          <div mat-card-avatar>
            <mwc-icon class="tangy-foreground-secondary">folder</mwc-icon>
          </div>
          <mat-card-title>
              <a>{{'Case Management'|translate}}</a>
          </mat-card-title>
          <mat-card-subtitle>{{'Review uploaded cases and related issues.'|translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
      <button mat-stroked-button color="accent" routerLink="cases">{{'Cases'|translate}}</button>
      <button mat-stroked-button color="accent" *appHasAPermission="let i;group:groupId; permission:'can_access_issues'" routerLink="issues">{{'Issues'|translate}}</button>
    </mat-card-actions>
    </mat-card>
  </span>

  <mat-card *appHasAPermission="let i;group:groupId; permission:'can_access_download_csv'" class="mat-elevation-z3">
    <mat-card-header>
      <div mat-card-avatar>
        <mwc-icon class="tangy-foreground-secondary">view_module</mwc-icon>
      </div>
      <mat-card-title>
        <a>{{'CSV Output'|translate}}</a>
      </mat-card-title>
      <mat-card-subtitle>{{'Download or configure a CSV Data Set, which is a zipped collection of CSVs. Configure the fields included in the CSV data output of a form using CSV Templates. The "Download individual CSVs" feature will be merged soon with the CSV Data Set feature soon since it has similar output.' |translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button mat-stroked-button color="accent" routerLink="csv-data-sets">{{'Download CSV Data Set'|translate}}</button>
      <button mat-stroked-button color="accent" routerLink="csv-templates">{{'CSV Templates for CSV Data Sets'|translate}}</button>
      <button mat-stroked-button color="accent" routerLink="download-csv">{{'Download individual CSVs (deprecated)'|translate}}</button>
    </mat-card-actions>

  </mat-card>

  <span *ngIf="config.enabledModules.includes('sync-protocol-2')">
    <mat-card *appHasAPermission="let i;group:groupId; permission:'can_access_database_conflicts'" class="is-link mat-elevation-z3" routerLink="database-conflicts">
      <mat-card-header>
          <div mat-card-avatar>
            <mwc-icon class="tangy-foreground-secondary">receipt</mwc-icon>
          </div>
          <mat-card-title>
            <a>{{'Database Conflicts'|translate}}</a>
          </mat-card-title>
          <mat-card-subtitle>{{'Review Database Conflicts.'|translate}}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </span>
  
  <mat-card *appHasAPermission="let i;group:groupId; permission:'can_access_uploads'" class="is-link mat-elevation-z3" routerLink="uploads">
    <mat-card-header>
      <div mat-card-avatar>
        <mwc-icon class="tangy-foreground-secondary">cloud_upload</mwc-icon>
      </div>
      <mat-card-title>
        <a>{{'Uploads'|translate}}</a>
      </mat-card-title>
      <mat-card-subtitle>{{'View uploaded form responses from devices.'|translate}}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

</ng-container>