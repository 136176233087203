<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<div class="tangy-full-width" id="container">
  <div class="configure-instructions">{{ "Configure the date range (optional), data set description, Personal Identifiable Information (PII) exclusion, and forms for the CSV data set." | translate }}</div>
  <form class="form">
    <div>
      <mat-form-field appearance="fill" color="primary">
        <mat-label>{{ "Month" | translate }}</mat-label>
        <mat-select
          name="selectedMonth"
          class="month"
          [(ngModel)]="selectedMonth"
        >
          <mat-option value="*" selected="selected">*</mat-option>
          <mat-option *ngFor="let month of months" value="{{ month }}">{{
            month
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="primary">
        <mat-label>{{ "Year" | translate }}</mat-label>
        <mat-select name="selectedYear" class="year" [(ngModel)]="selectedYear">
          <mat-option value="*" selected="selected">*</mat-option>
          <mat-option *ngFor="let year of years" value="{{ year }}">{{
            year
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
            name="descriptionInput"
            [(ngModel)]="description"
            #groupNameInput="ngModel"
            matInput
            placeholder="{{'Description'|translate}}"
            required
        >
    </mat-form-field>
      <mat-checkbox name="excludePII" [(ngModel)]="excludePII"
        >{{ "Exclude PII?" | translate }}
      </mat-checkbox>
    </div>
    <div class="select-instructions">{{ "Click the checkbox to the left of the 'Form title' column to select all forms. If there is a CSV Template available for a form, it will be displayed in the form's CSV Template dropdown." | translate }}</div>
    <table class="form-list materialish">
      <tr class="table-header new-csv-header">
        <td>
          <mat-checkbox name="toggle-select-all-forms" (change)="toggleSelectAllForms($event)"></mat-checkbox>
        </td>
        <td>
          Form Title
        </td>
        <td>
          CSV Template
        </td>
      </tr>
      <tr *ngFor="let form of forms" >
        <td>
          <mat-checkbox [checked]="allFormsSelected" (change)="onFormCheckBoxChange(form.id, $event)"></mat-checkbox>
        </td>
        <td>
          {{form.title}}
        </td>
        <td>
          <mat-form-field class="csv-template-selection" appearance="fill" color="primary">
            <mat-select name="template" [(ngModel)]="templateSelections[form.id]">
              <mat-option value="" selected>{{ "All data" | translate }}</mat-option>
              <mat-option *ngFor="let csvTemplate of form.csvTemplates" [value]="csvTemplate._id">{{csvTemplate.title}}</mat-option>
            </mat-select>
          </mat-form-field> 
        </td>
      </tr>
    </table>
    <button mat-raised-button color="warn" (click)="process()" [disabled]="selectedForms.length<1||!description">{{'Submit'|translate}}</button>
  </form>
</div>
